import { isMainnet } from 'config/constants';
import { ICurrentRewards } from 'types';

export const secondaryRewardTokenSymbol = {
  USDT: 'KON',
  KON: 'USDT',
};

export const dateConfig = {
  year: 'numeric' as const,
  month: 'numeric' as const,
  day: 'numeric' as const,
};

export const MS_IN_YEAR = isMainnet ? 31_536_000_000 : 600_000;
export const MS_IN_MONTH = isMainnet ? 2_592_000_000 : 50_000;

export const MAX_TERM_REWARDS_LOCK_TIMESTAMP = 12960000000;

export const nextClaimInfo = (
  countHarvest: string,
  lockUp: number,
  enteredAt: string,
  currentRewards: ICurrentRewards,
  rewardsTakenAfterThirdYear: number,
) => {
  const currentTime = Date.now();
  const endOfLockUp = +enteredAt + MS_IN_YEAR * (lockUp + 1);

  const endOfFirstYear = +enteredAt + MS_IN_YEAR;
  const endOfSecondYear = +enteredAt + MS_IN_YEAR * 2;
  const endOfThirdYear = +enteredAt + MS_IN_YEAR * 3;

  const yearsPassed = Math.floor((currentTime - +enteredAt) / MS_IN_YEAR);
  const monthesPassedAfterThirdYear = Math.floor(
    (currentTime - +enteredAt - MS_IN_YEAR * 3) / MS_IN_MONTH,
  );

  const isLeftSomeReward =
    +currentRewards.variablePartKON + +currentRewards.variablePartUSDT > 0 ||
    (currentTime > endOfLockUp && !currentRewards.gotFixed);

  if (lockUp === 2 && yearsPassed >= 3) {
    const msToNextClaim =
      monthesPassedAfterThirdYear > 5
        ? 0
        : endOfThirdYear + MS_IN_MONTH * (monthesPassedAfterThirdYear + 1) - currentTime;

    if (+countHarvest < 3) {
      return {
        msToNextClaim,
        canClaim: true,
      };
    }

    return {
      msToNextClaim,
      canClaim:
        rewardsTakenAfterThirdYear < monthesPassedAfterThirdYear + 1 &&
        isLeftSomeReward &&
        rewardsTakenAfterThirdYear < 6,
    };
  }

  const endOfYears = [endOfFirstYear, endOfSecondYear, endOfThirdYear];

  const msToNextClaim = currentTime > endOfLockUp ? 0 : endOfYears[yearsPassed] - currentTime;
  const canClaim =
    yearsPassed >= 1 &&
    +countHarvest < yearsPassed &&
    +countHarvest < lockUp + 1 &&
    isLeftSomeReward;

  return { msToNextClaim, canClaim };
};
