import React, { ReactElement, VFC } from 'react';

import { Modal, ModalProps } from 'components/Modal';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './InfoModal.styles';

export interface InfoModalProps extends ModalProps {
  text?: string | ReactElement;
  buttonText?: string;
  onConfirm?: () => void
}

export const InfoModal: VFC<InfoModalProps> = ({
  open,
  onClose,
  onConfirm,
  text = 'Some info from modal',
  buttonText = 'Ok',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.root} {...props}>
      <Typography component="div" className={clsx(classes.desc, 's')} variant="body1">
        {text}
      </Typography>
      <Button className={classes.button} variant="outlined" onClick={onConfirm || onClose}>
        <Typography className={clsx(classes.buttonText, 'strong')} variant="body1">{buttonText}</Typography>
      </Button>
    </Modal>
  );
};
